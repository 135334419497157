.custom-card {
  border: 1px dashed #B20070; /* Dashed border color */
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  text-align: center; /* Center the button inside the card */
}

.start-button {
  height: 44px;
  background-color: #B20070;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}
