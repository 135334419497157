/* Apply this to the main container to ensure it remains mobile-sized */
.container {
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow to simulate a mobile device */
  height: 100vh; /* Ensure the container takes up the full viewport height */
  overflow: hidden; /* Hide overflow to avoid scrollbars */
  display: flex;
  flex-direction: column; /* Ensure content stacks vertically */
  font-family: "Inter", sans-serif; /* Apply Inter Tight font */
}

/* Ensure the header, content, and footer take up appropriate space */
.header-bg {
  background-image: url("./assets/images/home/header_bg.png"); /* Adjust path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; /* Center the background image */
  height: 116px; /* Default height */
  position: relative; /* Ensure absolute positioning inside */
}

.header-content {
  width: 100%; /* Make sure the content spans the full width */
  position: absolute; /* Position absolutely within the header */
  bottom: 0; /* Align content to the bottom */
  left: 0; /* Align content to the left */
}

.greeting {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  margin-top: 8px;
}

.site-layout-content {
  padding: 0px;
  background: #f4f4f5;
  flex: 1; /* Allow content to expand and fill the available space */
  overflow-y: auto; /* Add vertical scrolling for content overflow */
}

.footer {
  text-align: center;
  background: #f0f0f0;
  padding: 10px;
}

.language-button {
  color: #fff;
}

/* Ensure mobile styling for smaller screens */
@media (max-width: 600px) {
  .header-bg {
    height: 100px; /* Adjust height for smaller screens */
  }

  .footer {
    font-size: 12px;
  }
}
